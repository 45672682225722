/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('van-button', {
    attrs: {
      "type": "primary"
    }
  }, [_vm._v("主要按钮")]), _c('van-button', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v("信息按钮")]), _c('van-button', {
    attrs: {
      "type": "default"
    }
  }, [_vm._v("默认按钮")]), _c('van-button', {
    attrs: {
      "type": "warning"
    }
  }, [_vm._v("警告按钮")]), _c('van-button', {
    attrs: {
      "type": "danger"
    }
  }, [_vm._v("危险按钮")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };